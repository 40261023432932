/* Styling for the Fill In Word-component */

/* P: Text elements in the exercise (i.e. the task explanation above the HR-line and the words in the sentence that is missing a word) */
p {
  display: inline;
  margin-bottom: 0;
  margin-top: 0.3rem;
  margin-right: 0.2rem;
  margin-left: 0.3rem;
}

/* Task: The container/box around the sentence that is missing a word. Contains taskGrid which contains the sentence itself. */
.task {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  padding: 1rem;
}

/* TaskPBorder: The selected word placed in the sentence */
.taskPBorder {
  background-color: #BCD0F7;
  border-radius: 10%;
  padding: 0.3rem;
  min-width: 2rem;
  max-width: 7rem;
  max-height: 2.45rem;
  margin: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  visibility: hidden
}

/* Vis: Toggles the visibility of the taskPBorder by using jQuery */
.vis {
  visibility: visible;
}

/* WordContainer: The place in the sentence where a word is missing */
.wordContainer {
  border-bottom: 1px solid #7CA3EE;
  min-width: 2rem;
  min-height: 1.5rem;
  max-width: 6rem;
  max-height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* WordGrid: The grid containing all the word-buttons */
.wordGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

/* TaskGrid: The grid containing the sentence itself. Inside the Task-element. */
.taskGrid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0;
  justify-items: center;
}


/* Media queries TaskGrid: Controls how the sentence that is missing a word is presented on smaller screens. */
@media screen and (max-width: 850px) {
  .taskGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 0;
  }
}
@media screen and (max-width: 650px) {
  .taskGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 0;
  }
}
@media screen and (max-width: 530px) {
  .taskGrid {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 0;
  }
}


/* Media queries WordGrid: Controls how the word-button grid is presented on smaller screens. */
@media screen and (max-width: 460px) {
  .wordGrid {
    grid-template-columns: 1fr 1fr; 
  }
}
