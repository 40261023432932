/* Styling for the Feedback-component */

/* PoengsumText: Text around the point score (i.e. 'Dine poeng' and 'Poengsummen din er..') */
#poengsumText {
    display: flex;
    justify-content: space-around;
    width: "50%";
}

/* Poengsum: Point score (i.e. '0 / 1') */
#poengsum {
    display: flex;
    justify-content: space-around;
    min-width: 100px;
    margin-top: 0;
}