/* Styling for the Unlock-component */

/* Numpad-buttons: Each button in the numpad-grid */
/* Media queries for size of buttons on smaller screens further down the document */

/* Grid layout:
    btn1    btn2    btn3
    btn4    btn5    btn6
    btn7    btn8    btn9
*/


#numpadButton1 {
    grid-row: 1;
    grid-column: 1;
    background-color: white;
    border-radius: 100%;
    padding: 10px;
    box-shadow: 1px 1px 10px #7CA3EE;
    margin: 5px;
    border: 1px #7CA3EE;
    height: 3.5em;
    width: 3.5em;
    justify-self: center;
}
  
 #numpadButton2 {
    grid-row: 1;
    grid-column: 2;
    background-color: white;
    border-radius: 100%;
    padding: 10px;
    box-shadow: 1px 1px 10px #7CA3EE;
    margin: 5px;
    border: 1px #7CA3EE;
    height: 3.5em;
    width: 3.5em;
    justify-self: center;
}

#numpadButton3 {
    grid-row: 1;
    grid-column: 3;
    background-color: white;
    border-radius: 100%;
    padding: 10px;
    box-shadow: 1px 1px 10px #7CA3EE;
    margin: 5px;
    border: 1px #7CA3EE;
    height: 3.5em;
    width: 3.5em;
    justify-self: center;
}

#numpadButton4 {
    grid-row: 2;
    grid-column: 1;
    background-color: white;
    border-radius: 100%;
    padding: 10px;
    box-shadow: 1px 1px 10px #7CA3EE;
    margin: 5px;
    border: 1px #7CA3EE;
    height: 3.5em;
    width: 3.5em;
    justify-self: center;
}
  
#numpadButton5 {
    grid-row: 2;
    grid-column: 2;
    background-color: white;
    border-radius: 100%;
    padding: 10px;
    box-shadow: 1px 1px 10px #7CA3EE;
    margin: 5px;
    border: 1px #7CA3EE;
    height: 3.5em;
    width: 3.5em;
    justify-self: center;
}
  
#numpadButton6 {
    grid-row: 2;
    grid-column: 3;
    background-color: white;
    border-radius: 100%;
    padding: 10px;
    box-shadow: 1px 1px 10px #7CA3EE;
    margin: 5px;
    border: 1px #7CA3EE;
    height: 3.5em;
    width: 3.5em;
    justify-self: center;
}
  
#numpadButton7 {
    grid-row: 3;
    grid-column: 1;
    background-color: white;
    border-radius: 100%;
    padding: 10px;
    box-shadow: 1px 1px 10px #7CA3EE;
    margin: 5px;
    border: 1px #7CA3EE;
    height: 3.5em;
    width: 3.5em;
    justify-self: center;
}
  
#numpadButton8 {
    grid-row: 3;
    grid-column: 2;
    background-color: white;
    border-radius: 100%;
    padding: 10px;
    box-shadow: 1px 1px 10px #7CA3EE;
    margin: 5px;
    border: 1px #7CA3EE;
    height: 3.5em;
    width: 3.5em;
    justify-self: center;
}
  
#numpadButton9 {
    grid-row: 3;
    grid-column: 3;
    background-color: white;
    border-radius: 100%;
    padding: 10px;
    box-shadow: 1px 1px 10px #7CA3EE;
    margin: 5px;
    border: 1px #7CA3EE;
    height: 3.5em;
    width: 3.5em;
    justify-self: center;
}

/* Sets color on hover over button */
#numpadButton1:hover, #numpadButton2:hover, #numpadButton3:hover, #numpadButton4:hover, #numpadButton5:hover, #numpadButton6:hover, #numpadButton7:hover, #numpadButton8:hover, #numpadButton9:hover {
    background-color: #7CA3EE;
}


/* Media queries regulate size of buttons on smaller screens */
@media only screen and (max-width: 500px) {

    #numpadButton1 {
        height: 2.8em;
        width: 2.8em;
    }

    #numpadButton2 {
        height: 2.8em;
        width: 2.8em;
    }

    #numpadButton3 {
        height: 2.8em;
        width: 2.8em;
    }

    #numpadButton4 {
        height: 2.8em;
        width: 2.8em;
    }

    #numpadButton5 {
        height: 2.8em;
        width: 2.8em;
    }

    #numpadButton6 {
        height: 2.8em;
        width: 2.8em;
    }
    
    #numpadButton7 {
        height: 2.8em;
        width: 2.8em;
    }

    #numpadButton8 {
        height: 2.8em;
        width: 2.8em;
    }
    
    #numpadButton9 {
        height: 2.8em;
        width: 2.8em;
    }
}


@media only screen and (max-width: 400px) {

    #numpadButton1 {
        height: 2.5em;
        width: 2.5em;
    }

    #numpadButton2 {
        height: 2.5em;
        width: 2.5em;
    }

    #numpadButton3 {
        height: 2.5em;
        width: 2.5em;
    }

    #numpadButton4 {
        height: 2.5em;
        width: 2.5em;
    }

    #numpadButton5 {
        height: 2.5em;
        width: 2.5em;
    }

    #numpadButton6 {
        height: 2.5em;
        width: 2.5em;
    }
    
    #numpadButton7 {
        height: 2.5em;
        width: 2.5em;
    }

    #numpadButton8 {
        height: 2.5em;
        width: 2.5em;
    }
    
    #numpadButton9 {
        height: 2.5em;
        width: 2.5em;
    }
}