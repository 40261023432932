/* Styling for the OverviewPage-component */

/* RootPaper: Paper-component containing the overviewComponent */
#rootPaper {
    background-color: #F5F5F5;
    max-width: 70%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 4px;
}

/* OverviewContent: A div containing the header, number of exercises, type of exercises, and the button to start the set */
#overviewContent {
    padding: 18px;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-self: center;
}

/* SetHeader: The header, title of the set */
#setHeader {
    font-size: 2em;
}

/* QuestionLine:  */
#questionLine {
    border-top: 1px solid #7CA3EE;
    margin: 10px 0 20px 0;
}

/* NoOfEx: Paragraph listing number of exercises in the set */
#noOfEx {
    font-size: 1em;
    margin-bottom: 10px;
}

/* TypeEx: Paragraph listing the different types of exercises in the set */
#typeEx {
    font-size: 1em;
    margin-bottom: 25px;
}

/* SpillBtn: The button to start playing the set */
#spillBtn {
    background-color: #7CA3EE;
    color: white;
    font-size: 1.1em;
    width: 80%;
    margin: auto;
}


/* RootPaper media queries: Controls the size of the overviewPage-component on smaller screens */
@media only screen and (max-width: 650px) {
    #rootPaper {
        margin: 20px auto;
        min-width: 88%;
    }
}
@media only screen and (max-width: 450px) {
    #rootPaper {
        margin: 10px auto;
        min-width: 94%;
    }  
}